/* eslint-disable import/extensions */
import {
  NosIdAppContext,
  NosIdAppContextType,
} from '@nosinovacao/nosid-mfe-common';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FC } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GlobalErrorHandler } from '@/components/global-error-handler/GlobalErrorHandler';
import { AppContext, AppContextType } from '@/context';
import { Router } from '@/routes';
import { StateProvider } from '@/state';

const queryClient = new QueryClient();

export const App: FC<{
  context: AppContextType;
  nosidContext: NosIdAppContextType;
}> = ({ context, nosidContext }) => (
  <GlobalErrorHandler sessionStorage={nosidContext.sessionStorageService}>
    <GoogleReCaptchaProvider
      reCaptchaKey={nosidContext.config.RecaptchaSiteKey}>
      <QueryClientProvider client={queryClient}>
        <StateProvider context={nosidContext}>
          <NosIdAppContext.Provider value={nosidContext}>
            <AppContext.Provider value={context}>
              <Router />
            </AppContext.Provider>
          </NosIdAppContext.Provider>
        </StateProvider>
      </QueryClientProvider>
    </GoogleReCaptchaProvider>
  </GlobalErrorHandler>
);
