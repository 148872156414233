import { IStorage } from '@nosinovacao/nosid-mfe-common';
import {
  User,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from 'oidc-client';
import {
  LOGIN_FINAL_REDIRECT_URL_STORAGE_KEY,
  LOGIN_HINT_STORAGE_KEY,
  LOGIN_REDIRECT_URI,
  LOGOUT_REDIRECT_URI,
  SILENT_REDIRECT_URI,
  TOKEN_STORAGE_KEY,
} from '@/constants';
import { OAuthConfig } from '@/models';

export class Auth {
  private readonly userManager: UserManager;

  constructor(
    protected readonly config: OAuthConfig,
    private readonly localStorageService: IStorage,
    private readonly sessionStorageService: IStorage,
  ) {
    this.userManager = new UserManager(this.getOAuthSettings());
  }

  private getOAuthSettings(): UserManagerSettings {
    return {
      authority: this.config.IdentityServerUrl,
      client_id: this.config.ClientId,
      redirect_uri: `${window.location.origin}${LOGIN_REDIRECT_URI}`,
      post_logout_redirect_uri: `${window.location.origin}${LOGOUT_REDIRECT_URI}`,
      response_type: this.config.ResponseType,
      scope: this.config.Scope,
      userStore: new WebStorageStateStore({ store: localStorage }),
      silent_redirect_uri: `${window.location.origin}${SILENT_REDIRECT_URI}`,
      automaticSilentRenew: true,
    };
  }

  async isLoggedIn(): Promise<boolean> {
    const token = this.getUserTokenFromStorage();
    if (token != null) {
      return await Promise.resolve(true);
    }
    return await this.userManager
      .getUser()
      .then((user) => {
        if (!user) {
          return false;
        }
        return !user?.expired;
      })
      .catch(() => false);
  }

  async processLoginRedirect(): Promise<boolean> {
    return await this.userManager
      .signinRedirectCallback()
      .then((user: User) => true)
      .catch(async () => {
        if (!(await this.isLoggedIn())) {
          await this.login();
        }
        return true;
      });
  }

  async processSilentRedirect(): Promise<boolean> {
    return await this.userManager.signinSilentCallback().then((user) => true);
  }

  async login(): Promise<void> {
    this.saveCurrentUrl();
    if (this.sessionStorageService.checkItem(LOGIN_HINT_STORAGE_KEY))
      await this.userManager.signinRedirect({
        login_hint: this.sessionStorageService.getString(
          LOGIN_HINT_STORAGE_KEY,
        ),
      });
    else await this.userManager.signinRedirect();
  }

  async logout(saveCurrentUrl = true): Promise<void> {
    const token = this.getUserTokenFromStorage();
    if ((token?.length ?? 0) !== 0) {
      this.deleteUserTokenFromStorage();
    }

    if (this.sessionStorageService.getString(LOGIN_HINT_STORAGE_KEY))
      this.deleteUserLoginHintFromStorage();

    if (saveCurrentUrl) this.saveCurrentUrl(true);
    await this.userManager.signoutRedirect({
      extraQueryParams: {
        client_id: this.config.ClientId,
      },
    });
  }

  async getToken(): Promise<string | null | undefined> {
    const token = this.getUserTokenFromStorage();
    if ((token?.length ?? 0) !== 0) {
      return await Promise.resolve(token);
    }
    return await this.userManager
      .getUser()
      .then((user) => {
        return user?.access_token;
      })
      .catch(() => null);
  }

  private getUserTokenFromStorage(): string | null {
    return this.sessionStorageService.getString(TOKEN_STORAGE_KEY);
  }

  private deleteUserTokenFromStorage(): void {
    this.sessionStorageService.remove(TOKEN_STORAGE_KEY);
  }

  private deleteUserLoginHintFromStorage(): void {
    this.sessionStorageService.remove(LOGIN_HINT_STORAGE_KEY);
  }

  private saveCurrentUrl(force: boolean = false): void {
    if (
      force ||
      !this.localStorageService.checkItem(LOGIN_FINAL_REDIRECT_URL_STORAGE_KEY)
    ) {
      const params = new URLSearchParams(window.location.search);
      params.delete('token');
      const search =
        (params.toString().length ?? 0) !== 0 ? `?${params.toString()}` : '';
      this.localStorageService.setString(
        LOGIN_FINAL_REDIRECT_URL_STORAGE_KEY,
        window.location.pathname + search,
      );
    }
  }
}
