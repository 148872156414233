import {
  PhoneService,
  AnalyticsOption,
  ActionType,
  EventParameters,
  ModuleName,
} from '@nosinovacao/nosid-mfe-common';
import { UsernameAliasToValidate } from '@/models';

class UtilsService {
  reloadApp(): void {
    window.location.href = window.location.origin;
  }

  refresh(): void {
    window.location.reload();
  }

  redirect(url: string): void {
    window.location.href = this.sanitizeUrl(url, window.location.origin);
  }

  openInNewWindow(link: string): void {
    window.open(link);
  }

  get pathname(): string {
    return window.location.pathname;
  }

  isNumeric(val: string): boolean {
    return val.trim().length > 0 && !Number.isNaN(+val);
  }

  getLogOption(
    phoneService: PhoneService,
    alias: string,
    aliasType: string,
  ): string {
    if (aliasType === 'Mobile') {
      const { Code } =
        phoneService.parseCountryCode(alias) ||
        phoneService.getDefaultCountry();
      return `${AnalyticsOption.Phone}@${Code}`;
    }

    return aliasType;
  }

  processEventParameters(
    actionType: ActionType,
    usernameAlias: UsernameAliasToValidate,
    phoneService: PhoneService,
  ): EventParameters {
    return {
      ActionType: actionType,
      Option: this.getLogOption(
        phoneService,
        usernameAlias.Username,
        usernameAlias.Type,
      ),
    };
  }

  sanitizeUrl(input: string, def: string): string {
    let url = input;
    if (!url?.trim?.()) {
      return def;
    }
    if (this.isBase64(url)) {
      url = atob(url);
    }

    const dangerousPatterns = [
      // Protocol-based attacks
      /javascript:/i, // Detect javascript: protocol
      /java[\s\S]*script:/i, // Obfuscated javascript:
      /data:[\s\S]*;/i, // Detect data URIs
      /data:text\/html/i, // Detect data:text/html
      /data:text\/javascript/i, // Detect data:text/javascript
      /data:application\/javascript/i, // Detect data:application/javascript
      /data:text\/xml/i, // Detect data:text/xml

      // HTML and script injection
      /<script[\s\S]*?>/i, // Detect <script> tags
      /<\/script>/i, // Detect </script> closing tags
      /<iframe[\s\S]*?>/i, // Detect <iframe> tags
      /<\/iframe>/i, // Detect </iframe> closing tags
      /<img[\s\S]*on\w+=/i, // Detect event handlers in <img>
      /<svg[\s\S]*on\w+=/i, // Detect event handlers in <svg>
      /<input[\s\S]*on\w+=/i, // Detect event handlers in <input>
    ];

    try {
      // Decode the URL to handle encoded payloads
      const decodedUrl = decodeURIComponent(url);

      // Normalize the URL by removing excessive whitespace and line breaks
      const normalizedUrl = decodedUrl.replace(/\s+/g, '');

      return dangerousPatterns.some((pattern) =>
        pattern.test(normalizedUrl.toLowerCase()),
      )
        ? def
        : url;
    } catch (e) {
      return def;
    }
  }

  private isBase64(str: string): boolean {
    if (!str?.trim?.()) {
      return false;
    }
    try {
      const reg =
        /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
      if (reg.test(str)) {
        return true;
      }
      const reEncodedValue = btoa(atob(str));
      if (
        reEncodedValue
          .replace(str, '')
          .split('')
          .every((char) => char === '=')
      ) {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  }

  private readonly routes: Map<string, string> = new Map<ModuleName, string>([
    [ModuleName.Home, '/'],
    [ModuleName.Tv, '/tv'],
    [ModuleName.Applications, '/apps'],
    [ModuleName.Account, '/account'],
    [ModuleName.Users, '/users'],
    [ModuleName.Security, '/security'],
  ]);

  getRoutePath(module: ModuleName): string | undefined {
    return this.routes.get(module);
  }
}

const globalForUtils = globalThis as unknown as { utils: UtilsService };

export const utilsService = globalForUtils.utils ?? new UtilsService();

if (import.meta.env.MODE !== 'production') {
  globalForUtils.utils = utilsService;
}
