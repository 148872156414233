import { Component, ReactNode } from 'react';
import { IStorage } from '@nosinovacao/nosid-mfe-common';
import { INVALID_DEVICE_DATE_ROUTE_STORAGE_KEY } from '@/constants';
import { utilsService } from '@/services';

export class GlobalErrorHandler extends Component<{
  children?: ReactNode;
  sessionStorage: IStorage;
}> {
  componentDidCatch(error: any, errorInfo: any): void {
    if (error.message.indexOf('iat is in the future: ') >= 0) {
      if (window.location.pathname !== '/error-device') {
        this.props.sessionStorage.setString(
          INVALID_DEVICE_DATE_ROUTE_STORAGE_KEY,
          window.location.href,
        );
        utilsService.redirect(`${window.location.origin}/generic/error-device`);
      }
    }
  }

  render(): ReactNode {
    return this.props.children;
  }
}
