import {
  ClientTemplate,
  IStorage,
  NosIdAppContextType,
  WidgetConfiguration,
  useNosIdAppContext,
} from '@nosinovacao/nosid-mfe-common';
import React, { useMemo } from 'react';
import {
  Api,
  AppleExternalProvider,
  FacebookExternalProvider,
  GoogleExternalProvider,
} from '@/api';
import { ExternalProvider } from '@/api/external-providers/external-provider';
import { OAuthConfig, PortalConfiguration } from '@/models';
import { Auth } from '@/services';
import { Endpoints } from '@/api/endpoints';

export interface AppContextType {
  auth: Auth;
  googleProvider: ExternalProvider;
  facebookProvider: ExternalProvider;
  appleProvider: ExternalProvider;
  api: Api<Endpoints>;
}

export const AppContext = React.createContext<AppContextType>(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as AppContextType,
);

type PortalContext = NosIdAppContextType &
  AppContextType & { config: PortalConfiguration };

export function useAppContext(): PortalContext {
  const nosidContext = useNosIdAppContext();
  const portalContext = React.useContext(AppContext);

  return useMemo(() => {
    return {
      ...nosidContext,
      ...portalContext,
      config: nosidContext.config as PortalConfiguration,
    };
  }, [nosidContext, portalContext]);
}

export function createApplicationContext(
  env: PortalConfiguration,
  templates: ClientTemplate[],
  widgets: WidgetConfiguration,
  localStorageService: IStorage,
  sessionStorageService: IStorage,
  nosidContext: NosIdAppContextType,
): AppContextType {
  const authConfig: OAuthConfig = {
    ...env.OAuthConfig,
    ...(env.OAuthConfig.ConfigForContext?.[
      nosidContext.templates.clientTemplate.EnterpriseContext
    ] ?? {}),
  };
  const auth = new Auth(authConfig, localStorageService, sessionStorageService);
  const api = new Api<Endpoints>(auth, env, nosidContext.templates);
  return {
    auth,
    googleProvider: new GoogleExternalProvider(api, env),
    facebookProvider: new FacebookExternalProvider(api, env),
    appleProvider: new AppleExternalProvider(api, env),
    api,
  };
}
