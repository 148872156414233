import { TemplatesService } from '@nosinovacao/nosid-mfe-common';
import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { PortalConfiguration } from '@/models';
import { Auth } from '@/services';
import {
  BaseEndpoints,
  ConfigsForDelete,
  ConfigsForGet,
  ConfigsForPatch,
  ConfigsForPost,
  ConfigsForPut,
  RequestOptionsforPath,
} from './types';

interface RequestParameters {
  params?: Record<string, string>;
  search?: Record<string, string>;
  captcha?: {
    token: string | null | undefined;
    challenge: boolean;
  };
  headers?: Record<string, string>;
}

export class Api<TModel extends BaseEndpoints> {
  private readonly axios: AxiosInstance;
  protected readonly CAPTCHA_HTTP_HEADER = 'X-Core-Captcha';
  protected readonly CAPTCHA_CHALLENGE_HTTP_HEADER = 'X-Core-Captcha-Challenge';

  constructor(
    auth: Auth,
    config: PortalConfiguration,
    template: TemplatesService,
  ) {
    const currentcontext = template.clientTemplate.EnterpriseContext ?? '';

    const baseURL =
      config.APIConfiguration.PortalApi.BaseUrlForContext?.[currentcontext] ??
      config.APIConfiguration.PortalApi.BaseUrl;

    this.axios = Axios.create({
      baseURL,
    });

    this.axios.interceptors.request.use(async (c) => {
      const token = await auth.getToken();
      c.headers.Authorization = `Bearer ${token ?? ''}`;
      return c;
    });
  }

  get axiosInstance(): AxiosInstance {
    return this.axios;
  }

  async get<TPATH extends keyof TModel>(
    path: TPATH,
    ...opts: RequestOptionsforPath<ConfigsForGet<TModel, TPATH>> extends never
      ? []
      : [RequestOptionsforPath<ConfigsForGet<TModel, TPATH>>]
  ): Promise<ConfigsForGet<TModel, TPATH>['response']> {
    const options = opts[0];
    const params = (
      options && 'params' in options ? options.params : {}
    ) as Record<string, string>;
    return await this.axios
      .get<ConfigsForGet<TModel, TPATH>['response']>(
        this.replaceInPath(path as string, params),
        this.getRequestOptions(options),
      )
      .then((res) => res.data);
  }

  async post<TPATH extends keyof TModel>(
    path: TPATH,
    body: ConfigsForPost<TModel, TPATH>['payload'],
    ...opts: RequestOptionsforPath<ConfigsForPost<TModel, TPATH>> extends never
      ? []
      : [RequestOptionsforPath<ConfigsForPost<TModel, TPATH>>]
  ): Promise<ConfigsForPost<TModel, TPATH>['response']> {
    const options = opts[0];
    const params = (
      options && 'params' in options ? options.params : {}
    ) as Record<string, string>;
    return await this.axios
      .post<
        typeof body,
        AxiosResponse<ConfigsForPost<TModel, TPATH>['response']>
      >(
        this.replaceInPath(path as string, params),
        body,
        this.getRequestOptions(options),
      )
      .then((res) => res.data);
  }

  async put<TPATH extends keyof TModel>(
    path: TPATH,
    body: ConfigsForPut<TModel, TPATH>['payload'],
    ...opts: RequestOptionsforPath<ConfigsForPut<TModel, TPATH>> extends never
      ? []
      : [RequestOptionsforPath<ConfigsForPut<TModel, TPATH>>]
  ): Promise<ConfigsForPut<TModel, TPATH>['response']> {
    const options = opts[0];
    const params = (
      options && 'params' in options ? options.params : {}
    ) as Record<string, string>;
    return await this.axios
      .put<
        ConfigsForPut<TModel, TPATH>['payload'],
        AxiosResponse<ConfigsForPut<TModel, TPATH>['response']>
      >(
        this.replaceInPath(path as string, params),
        body,
        this.getRequestOptions(options),
      )
      .then((res) => res.data);
  }

  async delete<TPATH extends keyof TModel>(
    path: TPATH,
    ...opts: RequestOptionsforPath<
      ConfigsForDelete<TModel, TPATH>
    > extends never
      ? []
      : [RequestOptionsforPath<ConfigsForDelete<TModel, TPATH>>]
  ): Promise<ConfigsForDelete<TModel, TPATH>['response']> {
    const options = opts[0];
    const params = (
      options && 'params' in options ? options.params : {}
    ) as Record<string, string>;
    return await this.axios
      .delete<unknown, AxiosResponse<ConfigsForPut<TModel, TPATH>['response']>>(
        this.replaceInPath(path as string, params),
        this.getRequestOptions(options),
      )
      .then((res) => res.data);
  }

  async patch<TPATH extends keyof TModel>(
    path: TPATH,
    body: ConfigsForPatch<TModel, TPATH>['payload'],
    ...opts: RequestOptionsforPath<ConfigsForPatch<TModel, TPATH>> extends never
      ? []
      : [RequestOptionsforPath<ConfigsForPatch<TModel, TPATH>>]
  ): Promise<ConfigsForPatch<TModel, TPATH>['response']> {
    const options = opts[0];
    const params = (
      options && 'params' in options ? options.params : {}
    ) as Record<string, string>;
    return await this.axios
      .patch<
        typeof body,
        AxiosResponse<ConfigsForPut<TModel, TPATH>['response']>
      >(
        this.replaceInPath(path as string, params),
        body,
        this.getRequestOptions(options),
      )
      .then((res) => res.data);
  }

  private getRequestOptions(
    options?: RequestParameters,
  ): AxiosRequestConfig<unknown> {
    let headers = options?.headers;
    const requiresCaptcha = !!options?.captcha;
    if (requiresCaptcha) {
      headers = headers ?? {};
      headers[this.CAPTCHA_HTTP_HEADER] = options?.captcha?.token ?? '';
      headers[this.CAPTCHA_CHALLENGE_HTTP_HEADER] = options?.captcha?.challenge
        ? 'true'
        : 'false';
    }
    return {
      params: options?.search,
      headers,
    };
  }

  private replaceInPath(
    path: string,
    replacements: Record<string, string>,
  ): string {
    return Object.entries(replacements).reduce(
      (acc, [key, value]) => acc.replace(`:${key}`, value),
      path,
    );
  }
}
