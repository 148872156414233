import { ExternalLoginProviders } from '@nosinovacao/nosid-mfe-common';
import { Api } from '../api';
import { PortalConfiguration } from '@/models';
import { Endpoints } from '../endpoints';

type GoogleInitProvider = undefined | null;

type AppleInitProvider = AppleSignInAPI.AuthI | null;

type FacebookInitProvider = facebook.FacebookStatic;

type InitProviders =
  | GoogleInitProvider
  | AppleInitProvider
  | FacebookInitProvider;

export abstract class ExternalProvider<T = InitProviders> {
  constructor(
    private readonly api: Api<Endpoints>,
    protected readonly config: PortalConfiguration,
  ) {}

  private oauthClientInternal: Promise<T | null> | null = null;

  get oAuthClient(): Promise<T | null> {
    if (!this.oauthClientInternal) {
      this.oauthClientInternal = this.initProvider();
    }
    return this.oauthClientInternal;
  }

  abstract initProvider(): Promise<T | null>;

  abstract login(): Promise<boolean>;

  abstract logout(): Promise<void>;

  protected async addSocialNetwork(
    provider: ExternalLoginProviders,
    token: string,
  ) {
    return await this.api
      .post('/api/v1/social-networks', undefined, {
        headers: {
          'Social-Network-Authorization': `${provider} ${token}`,
        },
      })
      .then(() => true);
  }

  protected async finishLogout(
    provider: ExternalLoginProviders,
  ): Promise<void> {
    await this.api.delete('/api/v1/social-networks', {
      search: {
        authProvider: provider,
      },
    });
  }
}
